import '../styles/ResearchPage.css';
import React, { useState, useEffect } from 'react';
import { useAuth } from '../authentication/AuthContext';
import trashBin from '../components/icons/trash_bin.png';
import { Box, Typography, Divider } from '@mui/material';
import Step1Survey from './Step1Survey';
import Step2Personas from './Step2Personas';
import Step3Responses from './Step3Responses';
import Step4FinalReview from './Step4FinalReview';
import { api } from '../services/api';

function ResearchPage() {
  const { user, sub } = useAuth();
  const [topics, setTopics] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);

  // Research workflow state from App.js
  const [userData, setUserData] = useState({
    userID: sub,
    researchID: '',
    surveyDescription: '',
    surveyQuestions: null,
    selectedPersonas: [],
    results: null,
  });

  const [completedSteps, setCompletedSteps] = useState({
    survey: false,
    personas: false,
    responses: false,
    finalReview: false,
  });

  // Load topics when component mounts
  useEffect(() => {
    if (sub) {
      api.getPreviousResearches(sub)
        .then(loadedTopics => {
          console.log('Loaded topics:', loadedTopics);
          setTopics(loadedTopics);
        })
        .catch(error => {
          console.error('Error loading research data:', error);
        });
    }
  }, [sub]);

  // Step completion handlers from App.js
  const handleSurveyComplete = (surveyData) => {
    setUserData(prev => ({
      ...prev,
      ...surveyData
    }));
    setCompletedSteps(prev => ({
      ...prev,
      survey: true
    }));
  };

  const handlePersonasComplete = (personaData) => {
    setUserData(prev => ({
      ...prev,
      selectedPersonas: personaData
    }));
    setCompletedSteps(prev => ({
      ...prev,
      personas: true
    }));
  };

  const handleResponsesComplete = () => {
    setCompletedSteps(prev => ({
      ...prev,
      responses: true
    }));
  };

  const handleFinalReviewComplete = () => {
    setCompletedSteps(prev => ({
      ...prev,
      finalReview: true
    }));
  };

  // Topic handlers
  const handleNewResearchClick = () => {
    setSelectedTopic({ id: Date.now(), name: 'New Research', questions: [] });
    setIsSidebarVisible(false);
    // Reset workflow state
    setCompletedSteps({
      survey: false,
      personas: false,
      responses: false,
      finalReview: false,
    });
    setUserData({
      userID: sub,
      researchID: '',
      surveyDescription: '',
      surveyQuestions: null,
      selectedPersonas: [],
      results: null,
    });
  };

  const handleTopicSelect = async (topic) => {
    console.log('Selected Topic:', topic);
    setSelectedTopic(topic);
    setUserData(prev => ({
      ...prev,
      researchID: topic.id,
      surveyQuestions: topic.questions || null,
    }));
  };

  const TopicList = ({ topics, onTopicClick, onTopicDelete }) => (
    <div className="topic-list">
      {topics.length === 0 ? (
        <div className="textbox container">Loading topics...</div>
      ) : (
        <ul>
          {topics.map((topic) => (
            <li 
              key={topic.id} 
              onClick={() => onTopicClick(topic)}
              className={topic.isPremium ? 'premium-topic' : ''}
            >
              <span>{topic.name}</span>
              <div className="topic-actions">
                {topic.isPremium && <span className="premium-star">★</span>}
                <img 
                  src={trashBin} 
                  alt="Delete" 
                  onClick={(e) => {
                    e.stopPropagation();
                    onTopicDelete(topic.id);
                  }} 
                  className="click-icon" 
                />
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );

  return (
    <div className="research-page">
      <aside className={`sidebar ${isSidebarVisible ? 'visible' : 'collapsed'}`}>
        <div className="sidebar-header">
          <h2>Your Research Topics</h2>
        </div>
        <button 
          className="button standout-button research-button" 
          style={{ maxWidth: 'none', width: '90%' }} 
          onClick={handleNewResearchClick}
        >
          New Research
        </button>
        <TopicList
          topics={topics}
          onTopicClick={handleTopicSelect}
          onTopicDelete={(topicId) => {
            const updatedTopics = topics.filter(t => t.id !== topicId);
            setTopics(updatedTopics);
            if (selectedTopic?.id === topicId) {
              setSelectedTopic(null);
            }
          }}
        />
      </aside>

      <button 
        className="toggle-sidebar"
        onClick={() => setIsSidebarVisible(!isSidebarVisible)}
      >
        {isSidebarVisible ? '←' : '→'}
      </button>

      <main className={`research-content ${isSidebarVisible ? 'visible' : 'collapsed'}`}>
        {selectedTopic ? (
          <Box sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
              {selectedTopic.name}
            </Typography>
            
            <Box sx={{ mb: 6 }}>
              <Step1Survey
                userData={userData}
                onComplete={handleSurveyComplete}
              />
            </Box>

            {completedSteps.survey && (
              <>
                <Divider sx={{ my: 4 }} />
                <Box sx={{ mb: 6 }}>
                  <Step2Personas
                    userData={userData}
                    onComplete={handlePersonasComplete}
                  />
                </Box>
              </>
            )}

            {completedSteps.personas && (
              <>
                <Divider sx={{ my: 4 }} />
                <Box sx={{ mb: 6 }}>
                  <Step3Responses
                    userData={userData}
                    setUserData={setUserData}
                    onComplete={handleResponsesComplete}
                  />
                </Box>
              </>
            )}

            {completedSteps.responses && (
              <>
                <Divider sx={{ my: 4 }} />
                <Box sx={{ mb: 6 }}>
                  <Step4FinalReview
                    userData={userData}
                    onComplete={handleFinalReviewComplete}
                  />
                </Box>
              </>
            )}
          </Box>
        ) : (
          <Box sx={{ p: 3 }}>
            <Typography variant="h5">
              Select a research topic or create a new one to begin
            </Typography>
          </Box>
        )}
      </main>
    </div>
  );
}

export default ResearchPage;
