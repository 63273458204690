import axios from 'axios';

const API_BASE_URL = 'http://127.0.0.1:8000';

export const api = {
  getPreviousResearches: async (userID) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/research/${userID}`);
      console.log('API response:', response.data); // Add debugging log
      const bodyData = JSON.parse(response.data.body);
      return bodyData.data;
    } catch (error) {
      console.error('API Error:', error.response?.data || error.message);
      throw error;
    }
  },

  getPreviousSurvey: async (userID, researchID) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/research/${userID}/${researchID}`);
      console.log('API response:', response.data);
      const bodyData = JSON.parse(response.data.body);
      const result = bodyData.data.survey;
      console.log('returned object:', result);
      return result;
    } catch (error) {
      console.error('API Error:', error.response?.data || error.message);
      throw error;
    }
  },
  createSurvey: async (userID, surveyDescription) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/research/create`, {
        userID,
        surveyDescription
      });
      console.log('API response:', response.data);
      const bodyData = JSON.parse(response.data.body);
      const researchID = bodyData.data.researchID;
      console.log('fetched new researchID:', researchID);
      const result = await api.getPreviousSurvey(userID, researchID);
      result.researchID = researchID;
      return result;

    } catch (error) {
      console.error('API Error:', error.response?.data || error.message);
      throw error;
    }
  },

  updateSurvey: async (userID, researchID, questions_list) => {
    try {
      const questions = questions_list.map(question => ({
        questionID: question.questionID,
        prompt: question.questionText,
        kind: question.questionType,
        vocab: question.responseOptions,
        max_length: question.maxLength,
        max: question.selectMax,
        min: question.selectMin
      }));

      const response = await axios.post(`${API_BASE_URL}/research/update_questions`, {
        userID,
        researchID,
        questions
      });
      console.log('API response:', response.data);
      return response.data;
    } catch (error) {
      console.error('API Error:', error.response?.data || error.message);
      throw error;
    }
  },

  selectPersonas: async (params) => {
    if (!params.userID || !params.researchID) {
      throw new Error('User ID and Research ID are required');
    }
    try {
      const requestBody = {
        userID: params.userID,
        researchID: params.researchID,
        numberOfPersonas: parseInt(params.numberOfResponders),
        gender: params.gender.length > 0 ? params.gender : 'all',
        province: params.province.length > 0 ? params.province : 'all',
        ageRange: params.ageRange.length > 0 ? params.ageRange : 'all'
      };
      
      console.log('Sending persona selection request:', requestBody);
      
      const response = await axios.post(`${API_BASE_URL}/personas/create`, requestBody);
      const bodyData = JSON.parse(response.data.body);
      const result = bodyData.data;
      console.log('returned object:', result);
      return result;
    } catch (error) {
      console.error('API Error:', error.response?.data || error.message);
      throw error;
    }
  },

  getPreviousPersonas: async (userID, researchID) => {
    if (!userID || !researchID) {
      throw new Error('User ID and Research ID are required');
    }
    try {
      const response = await axios.get(`${API_BASE_URL}/personas/${userID}/${researchID}`);
      console.log('API response:', response.data);
      const bodyData = JSON.parse(response.data.body);
      const result = bodyData.data;
      console.log('returned object:', result);
      return result;
    } catch (error) {
      console.error('API Error:', error.response?.data || error.message);
      throw error;
    }
  },

  getExistingResponses: async (userID, researchID) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/responses/${userID}/${researchID}`);
      console.log('API response:', response);
      const bodyData = JSON.parse(response.data.body).data;
      console.log('parsed body.data: ', bodyData)
      const result = bodyData.responses;
      console.log('returned object:', result);
      return result;
    } catch (error) {
      console.error('API Error:', error.response?.data || error.message);
      throw error;
    }
  },
  
  generateResponses: async (userID, researchID) => {
    const response = await axios.post(`${API_BASE_URL}/responses/create`, {
      userID,
      researchID
    });
    const result = await api.getExistingResponses(userID, researchID);
    console.log('responses object fetched after generating: ', result);
    return result;
  },

  generateQuestionResponses: async (userID, researchID, questionID) => {
    const response = await axios.post(`${API_BASE_URL}/responses/update_question`, {
      userID,
      researchID,
      questionID
    });
    const result = await api.getExistingResponses(userID, researchID);
    console.log('responses object fetched after re-generating: ', result);
    return result;
  },

  getDashboardStats: async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/dashboard/stats`);
      return response.data;
    } catch (error) {
      console.error('API Error:', error.response?.data || error.message);
      throw error;
    }
  },

  getDashboardData: async (dataType, filterField = null, filterValue = null) => {
    try {
      let url = `${API_BASE_URL}/dashboard/data/${dataType}`;
      if (filterField && filterValue) {
        url += `?filter_field=${filterField}&filter_value=${filterValue}`;
      }
      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      console.error('API Error:', error.response?.data || error.message);
      throw error;
    }
  },

  deleteDashboardRecord: async (dataType, id) => {
    try {
      const response = await axios.delete(`${API_BASE_URL}/dashboard/data/${dataType}/${id}`);
      return response.data;
    } catch (error) {
      console.error('API Error:', error.response?.data || error.message);
      throw error;
    }
  }
};