   // Engie_Prototype/app/frontend/src/components/ReportContent.js
   import React from 'react';
   import { Box, Typography } from '@mui/material';
   import jsPDF from 'jspdf';
   import html2canvas from 'html2canvas';

   export const handleReportGeneration = async () => {
     try {
       const element = document.getElementById('pdf-report-content');
       if (!element) {
         console.error('Report content element not found');
         return;
       }

       // Create a clone of the element
       const clone = element.cloneNode(true);
       document.body.appendChild(clone);
       
       // Make the clone visible and ensure it has dimensions
       clone.style.display = 'block';
       clone.style.position = 'fixed';
       clone.style.top = '-9999px';
       clone.style.left = '-9999px';
       clone.style.width = '800px'; // Set a specific width
       clone.style.height = 'auto';
       
       // Generate the PDF
       const canvas = await html2canvas(clone, {
         logging: false,
         useCORS: true,
         scale: 2,
         width: 800,
         windowWidth: 800
       });
       
       // Clean up the clone
       document.body.removeChild(clone);
       
       const imgData = canvas.toDataURL('image/png');
       
       const pdf = new jsPDF();
       const pdfWidth = pdf.internal.pageSize.getWidth();
       const pdfHeight = pdf.internal.pageSize.getHeight();
       const imgWidth = canvas.width;
       const imgHeight = canvas.height;
       const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
       
       pdf.addImage(
         imgData, 
         'PNG', 
         0, 
         0, 
         imgWidth * ratio, 
         imgHeight * ratio
       );
       pdf.save('survey_report.pdf');
     } catch (err) {
       console.error('Error generating report:', err);
       console.error('Error details:', {
         elementExists: !!document.getElementById('pdf-report-content'),
         elementContent: document.getElementById('pdf-report-content')?.innerHTML
       });
     }
   };

   const ReportContent = ({ userData }) => {
     return (
       <Box id="pdf-report-content" style={{ display: 'none' }}>
         <Typography variant="h5" gutterBottom>
           PDF Report INSTANT FLOWS
         </Typography>
         <Typography>
           Now we can add content here using MUI Typography :D
         </Typography>
       </Box>
     );
   };

   export default ReportContent;