import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Paper,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Grid,
  ButtonGroup,
  InputAdornment
} from '@mui/material';
import { api } from '../services/api';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

function Step1Survey({ userData, onComplete }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  // And another for “warnings” about removing choices
  const [warning, setWarning] = useState("");
  const [usePrevious, setUsePrevious] = useState(false);
  const [previousResearches, setPreviousResearches] = useState([]);
  const [formData, setFormData] = useState({
    userID: userData.userID || '',
    surveyDescription: '',
    researchID: ''
  });
  const [editedSurvey, setEditedSurvey] = useState(userData.surveyQuestions || []);

  const loadPreviousSurvey = async (researchID) => {
    try {
      setLoading(true);
      const result = await api.getPreviousSurvey(formData.userID, researchID);


      const formattedQuestions = Object.keys(result.questions).map(questionID => {
        const questionData = result.questions[questionID];
        const isMultipleChoice = questionData.kind === 'multiple-choice';
      
        let selectMin;
        let selectMax;
      
        if (isMultipleChoice) {
          // If min/max exist, use them; otherwise, set default
          selectMin = typeof questionData.min !== 'undefined' ? questionData.min : 1;
          selectMax = typeof questionData.max !== 'undefined' ? questionData.max : questionData.vocab.length;
        }
      
        return {
          questionID,
          questionText: questionData.prompt,
          questionType: questionData.kind,
          responseOptions: isMultipleChoice ? questionData.vocab : [],
          maxLength: questionData.kind === 'open' ? questionData.max_length : undefined,
          selectMin,
          selectMax,
        };
      });      
      
      
      setEditedSurvey(formattedQuestions);
      onComplete({
        userID: formData.userID,
        researchID: researchID,
        surveyQuestions: formattedQuestions
      });
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handlePreviousResearchChange = async (e) => {
    const selectedresearchID = e.target.value;
    setFormData(prev => ({ ...prev, researchID: selectedresearchID }));
    setError(null); // Clear the error when the form data changes
    if (selectedresearchID) {
      await loadPreviousSurvey(selectedresearchID);
    }
  };

  const loadPreviousResearches = useCallback(async () => {
    try {
      const response = await api.getPreviousResearches(formData.userID);
      console.log('Previous researches response:', response);
      setPreviousResearches(response || []);
      setError(null); // Clear the error when the form data changes
    } catch (err) {
      console.error('Failed to load previous researches:', err);
      setError('Failed to load previous researches');
    }
  }, [formData.userID]);

  useEffect(() => {
    if (usePrevious) {
      loadPreviousResearches();
    }
  }, [usePrevious, loadPreviousResearches]);

  // ADD THIS EFFECT:
  useEffect(() => {
    // If we're set to pull from a previous research and have a researchID,
    // re-fetch the survey from the server. (Ensures fresh data on reload)
    if (usePrevious && formData.researchID) {
      loadPreviousSurvey(formData.researchID);
    }
  }, [usePrevious, formData.researchID]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
  
    try {
      let result;
      if (usePrevious) {
        result = await api.getPreviousSurvey(formData.userID, formData.researchID);
      } else {
        const createResult = await api.createSurvey(formData.userID, formData.surveyDescription);
        console.log("createResult:", createResult);
        // Format the questions in the same way as getPreviousSurvey
        const formattedQuestions = Object.keys(createResult.questions).map(questionID => {
          const questionData = createResult.questions[questionID];
          const isMultipleChoice = questionData.kind === 'multiple-choice';
        
          let selectMin;
          let selectMax;
        
          if (isMultipleChoice) {
            // If min/max exist, use them; otherwise, set default
            selectMin = typeof questionData.min !== 'undefined' ? questionData.min : 1;
            selectMax = typeof questionData.max !== 'undefined' ? questionData.max : questionData.vocab.length;
          }
        
          return {
            questionID,
            questionText: questionData.prompt,
            questionType: questionData.kind,
            responseOptions: isMultipleChoice ? questionData.vocab : [],
            maxLength: questionData.kind === 'open' ? questionData.max_length : undefined,
            selectMin,
            selectMax,
          };
        });

        
  
        setEditedSurvey(formattedQuestions);
        onComplete({
          userID: formData.userID,
          researchID: createResult.researchID,
          surveyQuestions: formattedQuestions
        });
      }
  
      if (usePrevious) {
        setEditedSurvey(result.questions);
        onComplete({
          userID: formData.userID,
          researchID: formData.researchID,
          surveyQuestions: result.questions
        });
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

    /**
   * Before calling the update API, validate:
   * 1) Every question has non-empty text.
   * 2) Every multiple-choice question has >=2 options.
   */
  const handleContinue = async () => {
    try {
      setLoading(true);
      setError(null);

      // Validate that all multiple-choice questions have at least two options
      for (let i = 0; i < editedSurvey.length; i++) {
        const question = editedSurvey[i];
        // 1) Check question text
        if (!question.questionText || !question.questionText.trim()) {
          setError(
            `Question #${i + 1} has no text. Please enter some text for this question.`
          );
          setLoading(false);
          return;
        }

        // 2) Check if question is multiple-choice with fewer than 2 options
        if (
          question.questionType === 'multiple-choice' &&
          (!question.responseOptions || question.responseOptions.length < 2)
        ) {
          setError(
            `Question #${i + 1} is multiple choice but has fewer than 2 options. Please fix before continuing.`
          );
          setLoading(false);
          return;
        }
      }

      // If validation passes, update the survey
      await api.updateSurvey(
        formData.userID,
        formData.researchID, 
        editedSurvey
      );
      
      // Only show success and update parent component after successful update
      onComplete({
        userID: formData.userID,
        researchID: formData.researchID,
        surveyQuestions: editedSurvey
      });
      
      // Show the survey editor after successful update
    } catch (err) {
      setError('Failed to update survey: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  const addQuestion = () => {
    setEditedSurvey([...editedSurvey, { questionText: '', questionType: 'open', responseOptions: [], maxLength: 200}]);
  };

  const removeQuestion = (index) => {
    const updatedSurvey = [...editedSurvey];
    updatedSurvey.splice(index, 1);
    setEditedSurvey(updatedSurvey);
  };

  const removeOption = (questionIndex, optionIndex) => {
    setWarning(""); // Clear any prior warning
    const updatedSurvey = [...editedSurvey];
    const question = updatedSurvey[questionIndex];

    question.responseOptions.splice(optionIndex, 1);

    // If it's multiple-choice but now has <2 options, show a warning
    if (
      question.questionType === 'multiple-choice' &&
      question.responseOptions.length < 2
    ) {
      setWarning(
        `Question #${questionIndex + 1} now has only ${question.responseOptions.length} option(s). You must have at least 2 before continuing.`
      );
    }

    // updatedSurvey[questionIndex].responseOptions.splice(optionIndex, 1);
    setEditedSurvey(updatedSurvey);
  };

  const addOption = (questionIndex) => {
    setWarning(""); // Clear any prior warning
    const updatedSurvey = [...editedSurvey];
    updatedSurvey[questionIndex].responseOptions.push('');
    setEditedSurvey(updatedSurvey);
  };

  const toggleQuestionType = (questionIndex) => {
    setWarning("");

    const updatedSurvey = [...editedSurvey];
    const currentQuestion = updatedSurvey[questionIndex];
    
    // Store current options before changing type
    const previousOptions = currentQuestion.responseOptions || [];
    
    // Toggle the type
    currentQuestion.questionType = 
      currentQuestion.questionType === 'open' ? 'multiple-choice' : 'open';
    
    // Preserve the options even when switching to 'open'
    currentQuestion.responseOptions = previousOptions;
    // if (currentQuestion.questionType === 'open') {
    //   // Switch to multiple choice
    //   currentQuestion.questionType = 'multiple-choice';
    //   if (!currentQuestion.responseOptions || currentQuestion.responseOptions.length < 2) {
    //     currentQuestion.responseOptions = ['', ''];
    //   }
    // } else {
    //   // Switch to open
    //   currentQuestion.questionType = 'open';
    //   // Typically, we keep the 'responseOptions' in case user switches back
    // }
    setEditedSurvey(updatedSurvey);
  };

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Step 1: Create Survey
      </Typography>

      <Paper sx={{ p: 3, mt: 2 }}>
        <Box component="form" onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="User ID"
            value={formData.userID}
            onChange={(e) => {
              setFormData(prev => ({ ...prev, userID: e.target.value }));
              setError(null); // Clear the error when the form data changes
            }}
            margin="normal"
            required
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={usePrevious}
                onChange={(e) => {
                  setUsePrevious(e.target.checked);
                  setError(null); // Clear the error when the form data changes
                }}
              />
            }
            label="Use previous research"
          />

          {usePrevious ? (
            <FormControl fullWidth margin="normal">
              <InputLabel>Previous Research</InputLabel>
              <Select
                value={formData.researchID}
                onChange={handlePreviousResearchChange}
                required
              >
                {previousResearches && previousResearches.length > 0 ? (
                  previousResearches.map((research) => (
                    <MenuItem key={research.researchID} value={research.researchID}>
                      {research.description}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No previous research found</MenuItem>
                )}
              </Select>
            </FormControl>
          ) : (
            <TextField
              fullWidth
              label="Survey Description"
              multiline
              rows={4}
              value={formData.surveyDescription}
              onChange={(e) => {
                setFormData(prev => ({ ...prev, surveyDescription: e.target.value }));
                setError(null); // Clear the error when the form data changes
              }}
              margin="normal"
              required
            />
          )}

          {error && (
            <Typography color="error" sx={{ mt: 2 }}>
              Error: {error}
            </Typography>
          )}

          {!usePrevious && (
            <Button
              variant="contained"
              type="submit"
              disabled={loading}
              sx={{ mt: 2 }}
            >
              {loading ? <CircularProgress size={24} /> : 'Generate Survey'}
            </Button>
          )}
        </Box>
      </Paper>

      {editedSurvey && editedSurvey.length > 0 && (
        <Paper sx={{ p: 3, mt: 3 }}>
          <Typography variant="h6" gutterBottom>
            Edit Survey
          </Typography>

          {/* Show a non-blocking “warning” for multiple-choice questions that have under 2 options. */}
          {warning && (
            <Typography sx={{ color: 'orange', mt: 1 }}>
              {warning}
            </Typography>
          )}

          <Grid container spacing={2}>
            {editedSurvey.map((question, index) => (
              <Grid item xs={12} key={index}>
                <TextField
                  fullWidth
                  label={`Question ${index + 1}`}
                  value={question.questionText}
                  onChange={(e) => {
                    const updatedSurvey = [...editedSurvey];
                    updatedSurvey[index].questionText = e.target.value;
                    setEditedSurvey(updatedSurvey);
                  }}
                  margin="normal"
                />
                <ButtonGroup 
                  variant="contained" 
                  sx={{ mt: 1, mb: 2 }}
                  aria-label="question type toggle"
                >
                  <Button
                    onClick={() => toggleQuestionType(index)}
                    sx={{
                      bgcolor: question.questionType === 'open' ? 'primary.main' : 'grey.400',
                      '&:hover': {
                        bgcolor: question.questionType === 'open' ? 'primary.dark' : 'grey.500',
                      }
                    }}
                  >
                    Open-Ended
                  </Button>
                  <Button
                    onClick={() => toggleQuestionType(index)}
                    sx={{
                      bgcolor: question.questionType === 'multiple-choice' ? 'primary.main' : 'grey.400',
                      '&:hover': {
                        bgcolor: question.questionType === 'multiple-choice' ? 'primary.dark' : 'grey.500',
                      }
                    }}
                  >
                    Multiple Choice
                  </Button>
                </ButtonGroup>

                <IconButton onClick={() => removeQuestion(index)}>
                  <RemoveIcon /> Remove Question
                </IconButton>
                
                {/* Show options if type is multiple_choice OR if there are stored options */}
                {(question.questionType === 'multiple-choice' || question.responseOptions?.length > 0) && (
                  <Box sx={{ pl: 2 }}>
                    <TextField
                      label="Select Max"
                      type="number"
                      value={question.selectMax || ""}
                      onChange={(e) => {
                        const updatedSurvey = [...editedSurvey];
                        updatedSurvey[index].selectMax = e.target.value;
                        setEditedSurvey(updatedSurvey);
                      }}
                      InputProps={{ inputProps: { min: 0 } }}
                      margin="normal"
                      disabled={question.questionType === 'open'}
                    />
                    <TextField
                      label="Select Min"
                      type="number"
                      value={question.selectMin || ""}
                      onChange={(e) => {
                        const updatedSurvey = [...editedSurvey];
                        updatedSurvey[index].selectMin = e.target.value;
                        setEditedSurvey(updatedSurvey);
                      }}
                      InputProps={{ inputProps: { min: 0 } }}
                      margin="normal"
                      disabled={question.questionType === 'open'}
                    />
                    {question.responseOptions.map((option, i) => (
                      <Box key={i} sx={{ display: 'flex', alignItems: 'center' }}>
                        <TextField
                          label={`Option ${i + 1}`}
                          value={option}
                          onChange={(e) => {
                            const updatedSurvey = [...editedSurvey];
                            updatedSurvey[index].responseOptions[i] = e.target.value;
                            setEditedSurvey(updatedSurvey);
                          }}
                          margin="normal"
                          disabled={question.questionType === 'open'}
                        />
                        <IconButton 
                          onClick={() => removeOption(index, i)}
                          disabled={question.questionType === 'open'}
                        >
                          <RemoveIcon />
                        </IconButton>
                      </Box>
                    ))}
                    <IconButton 
                      onClick={() => addOption(index)}
                      disabled={question.questionType === 'open'}
                    >
                      <AddIcon />
                      Add Option
                    </IconButton>
                  </Box>
                )}
                {question.questionType === 'open' && (
                  <Box sx={{ mt: 2 }}>
                    <TextField
                      label="Max Length"
                      type="number"
                      value={question.maxLength || ""}
                      onChange={(e) => {
                        const updatedSurvey = [...editedSurvey];
                        updatedSurvey[index].maxLength = e.target.value;
                        setEditedSurvey(updatedSurvey);
                      }}
                      InputProps={{ inputProps: { min: 0 } }}
                      margin="normal"
                    />
                  </Box>
                )}
                {/* Add after line 311 in Step1Survey.js */}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={question.hasFixedPercentages}
                      onChange={(e) => {
                        const updatedSurvey = [...editedSurvey];
                        updatedSurvey[index].hasFixedPercentages = e.target.checked;
                        if (e.target.checked && !updatedSurvey[index].responsePercentages) {
                          updatedSurvey[index].responsePercentages = question.responseOptions.map(() => 0);
                        }
                        setEditedSurvey(updatedSurvey);
                      }}
                    />
                  }
                  label="Fix Response Percentages"
                />

                {question.hasFixedPercentages && question.responseOptions.map((option, i) => (
                  <Box key={i} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Typography>{option}:</Typography>
                    <TextField
                      type="number"
                      value={question.responsePercentages?.[i] || 0}
                      onChange={(e) => {
                        const updatedSurvey = [...editedSurvey];
                        const percentages = [...(updatedSurvey[index].responsePercentages || [])];
                        percentages[i] = Number(e.target.value);
                        updatedSurvey[index].responsePercentages = percentages;
                        setEditedSurvey(updatedSurvey);
                      }}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                      }}
                      error={question.responsePercentages?.reduce((a, b) => a + b, 0) !== 100}
                      helperText={question.responsePercentages?.reduce((a, b) => a + b, 0) !== 100 ? 
                        "Percentages must sum to 100" : ""}
                    />
                  </Box>
                ))}
              </Grid>
            ))}
          </Grid>
          <IconButton onClick={addQuestion}>
            <AddIcon />
            Add Question
          </IconButton>
          <Button
            variant="contained"
            onClick={handleContinue}
            sx={{ mt: 2 }}
          >
            Continue
          </Button>
        </Paper>
      )}
    </Box>
  );
}

export default Step1Survey;