import React, { useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  ToggleButton,
  ToggleButtonGroup,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { api } from '../services/api';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { handleReportGeneration } from './ReportContent';
import ReportContent from './ReportContent';

function Step3Responses({ userData, setUserData }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [viewMode, setViewMode] = useState('byQuestion'); // 'byQuestion' or 'byPersona'

  const handleGenerateResponses = async () => {
    setLoading(true);
    setError(null);

    try {
      const result = await api.generateResponses(
        userData.userID,
        userData.researchID
      );

      const researchID = userData.researchID;

      const responses = Object.entries(result).flatMap(([personaID, questionMap]) =>
        Object.entries(questionMap).map(([questionID, response]) => ({
          personaID,
          questionID,
          response,
          researchID,
        }))
      );

      setUserData(prev => ({
        ...prev,
        results: responses
      }));
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleLoadExistingResponses = async () => {
    setLoading(true);
    setError(null);

    try {
      const result = await api.getExistingResponses(
        userData.userID,
        userData.researchID
      );

      const researchID = userData.researchID;

      const responses = Object.entries(result).flatMap(([personaID, questionMap]) =>
        Object.entries(questionMap).map(([questionID, response]) => ({
          personaID,
          questionID,
          response,
          researchID,
        }))
      );

      setUserData(prev => ({
        ...prev,
        results: responses
      }));
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
    // NEW Handler: Regenerate responses for a single question
    const handleGenerateQuestionResponses = async questionID => {
      setLoading(true);
      setError(null);
  
      try {
        const result = await api.generateQuestionResponses(
          userData.userID,
          userData.researchID,
          questionID
        );
  
        const researchID = userData.researchID;
  
        const responses = Object.entries(result).flatMap(([personaID, questionMap]) =>
          Object.entries(questionMap).map(([questionID, response]) => ({
            personaID,
            questionID,
            response,
            researchID,
          }))
        );
  
        setUserData(prev => ({
          ...prev,
          results: responses
        }));
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

  const handleViewModeChange = (event, newMode) => {
    if (newMode !== null) {
      setViewMode(newMode);
    }
  };

  const organizeByQuestion = () => {                                                        // function that returns what is in the brackets
    if (!userData.results) return [];                                                       // If there are no results, return an empty array
    
    console.log('Survey Questions:', userData.surveyQuestions);                             // log the survey questions and the results to the console for debugging purposes. 
    console.log('Results:', userData.results);
    
    function getIds(array) {
      return array.map(item => item.id);                                                    // makes a new array containing the ids of all the objects in the array
    }
  
    // const personaIds = getIds(userData.selectedPersonas);

    console.log('Selected personas: ', userData.selectedPersonas);
    const personaIds = userData.selectedPersonas.map(item => item.id);

    console.log('Persona ids:', personaIds);

    return userData.surveyQuestions.map(question => {                                       // iterates over each question in the userData.surveyQuestions array. For each question, it performs the following operations
      
      const questionResponses = userData.results.filter(                                    // creates a new array called questionResponses that contain only the filtered results from userData.results
        r => r.questionID === question.questionID && personaIds.includes(r.personaID)       // for every result r it returns true if the result.questionID is the same as as the questionId of the question you're currently iterating through,
      );

      console.log(`Responses for question ${question.questionID}:`, questionResponses);

      return {
        question,
        responses: questionResponses
      };
    });
  };

  const organizeByPersona = () => {                                                                     // function that returns what is in the brackets
    if (!userData.results) return [];                                                                   // if there are no userData results, return an empty array
    
    
    console.log("researchID from userData:",userData.researchID);
    console.log("userData.results: ",userData.results);

    return userData.selectedPersonas.map(persona => {                                                   // iterates over each persona in userData.selectedPersonas. For each variable persona it performs the following operations
      const personaResponses = userData.results.filter(                                                 // creates a new array called personaResponses that contain only the filtered results from userData.results
        r => parseInt(r.personaID) === parseInt(persona.id) && r.researchID === userData.researchID     // for every result r in userData.results, it returns true if the personaID of the response matches the id of the persona from selected personas you are currently iterating through. It also checks if the result belongs to the current research. 
      );

      console.log(`Responses for persona ${persona.id}:`, personaResponses);

      return {                                                                                          // it returns every persona together with all its responses. 
        persona,
        responses: personaResponses
      };
    });
  };
  

  const renderResponsesByQuestion = () => {
    const organizedData = organizeByQuestion();
    
    return (
      <Box>
        {organizedData.map((item, index) => (
          <Accordion key={index} defaultExpanded={index === 0}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">
                Question {index + 1}: {item.question.questionText}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {/* NEW Button to regenerate responses for this question */}
              <Box sx={{ mb: 1 }}>
                <Button
                  variant="outlined"
                  size="small"
                  disabled={loading}
                  onClick={() =>
                    handleGenerateQuestionResponses(item.question.questionID)
                  }
                >
                  {loading ? 'Regenerating...' : 'Regenerate Responses for this Question'}
                </Button>
              </Box>

              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Persona</TableCell>
                      <TableCell>Response</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {item.responses.map((response, rIndex) => (
                      <TableRow key={rIndex}>
                        <TableCell>
                          {userData.selectedPersonas.find(p => p.id === response.personaID)?.name || response.personaID}
                        </TableCell>
                        <TableCell>
                                                    
                          {/* {response.response.split('\\n').map((line, i) => (
                            <React.Fragment key={i}>
                              {line}
                              {i < response.response.split('\\n').length - 1 && <br />}
                            </React.Fragment>
                          ))} */}
                          {Array.isArray(response.response) ? (
                            response.response.map((ans, i) => (
                              <React.Fragment key={i}>
                                {ans}
                                {i < response.response.length - 1 && <br />}
                              </React.Fragment>
                            ))
                          ) : typeof response.response === 'string' ? (
                            response.response.split('\\n').map((line, i) => (
                              <React.Fragment key={i}>
                                {line}
                                {i < response.response.split('\\n').length - 1 && <br />}
                              </React.Fragment>
                            ))
                          ) : (
                            // Fallback if it's neither a string nor an array
                            <>{JSON.stringify(response.response)}</>
                          )}

                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    );
  };

  const renderResponsesByPersona = () => {
    const organizedData = organizeByPersona();
    
    return (
      <Box>
        {organizedData.map((item, index) => (
          <Accordion key={index} defaultExpanded={index === 0}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">
                Persona {item.persona.id} ({item.persona.age_range}, {item.persona.gender})
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {/* NEW Button to regenerate responses for this persona
              <Box sx={{ mb: 1 }}>
                <Button
                  variant="outlined"
                  size="small"
                  disabled={loading}
                  onClick={() =>
                    handleGeneratePersonaResponses(item.persona.id)
                  }
                >
                  {loading ? 'Regenerating...' : 'Regenerate Responses of this Persona'}
                </Button>
              </Box> */}

              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Question</TableCell>
                      <TableCell>Response</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {item.responses.map((response, rIndex) => (
                      <TableRow key={rIndex}>
                        <TableCell>
                          {userData.surveyQuestions.find(q => q.questionID === response.questionID)?.questionText || response.questionID}
                        </TableCell>
                        <TableCell>
                          {/* {response.response.split('\\n').map((line, i) => (
                            <React.Fragment key={i}>
                              {line}
                              {i < response.response.split('\\n').length - 1 && <br />}
                            </React.Fragment>
                          ))} */}
                          {Array.isArray(response.response) ? (
                            response.response.map((ans, i) => (
                              <React.Fragment key={i}>
                                {ans}
                                {i < response.response.length - 1 && <br />}
                              </React.Fragment>
                            ))
                          ) : typeof response.response === 'string' ? (
                            response.response.split('\\n').map((line, i) => (
                              <React.Fragment key={i}>
                                {line}
                                {i < response.response.split('\\n').length - 1 && <br />}
                              </React.Fragment>
                            ))
                          ) : (
                            // Fallback if it's neither a string nor an array
                            <>{JSON.stringify(response.response)}</>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    );
  };

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Step 3: Generate Responses
      </Typography>

      <Paper sx={{ p: 3, mt: 2 }}>
        {!userData.results ? (
          <></>
        ) : (
          <Box>
            <Box sx={{ mb: 2 }}>
              <ToggleButtonGroup
                value={viewMode}
                exclusive
                onChange={handleViewModeChange}
                aria-label="view mode"
              >
                <ToggleButton value="byQuestion" aria-label="by question">
                  View by Question
                </ToggleButton>
                <ToggleButton value="byPersona" aria-label="by persona">
                  View by Persona
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>

            {viewMode === 'byQuestion' ? renderResponsesByQuestion() : renderResponsesByPersona()}
          </Box>
        )}
      </Paper>
      <Box>
            <Button
              variant="contained"
              onClick={handleGenerateResponses}
              disabled={loading}
              sx={{ mr: 2 }}
            >
              {loading ? <CircularProgress size={24} /> : 'Generate New Responses'}
            </Button>
            
            <Button
              variant="outlined"
              onClick={handleLoadExistingResponses}
              disabled={loading}
            >
              Load Existing Responses
            </Button>

            <Button
              variant="outlined"
              onClick={() => handleReportGeneration(userData)}
              disabled={loading}
            >
              Download PDF report
            </Button>

            {error && (
              <Typography color="error" sx={{ mt: 2 }}>
                Error: {error}
              </Typography>
            )}
          </Box>
      
      <div style={{ display: 'none' }}>
        <ReportContent userData={userData} />
      </div>

      
    </Box>
  );
}

export default Step3Responses;